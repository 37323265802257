import React from 'react';
import Item from './Item';
import styled from 'styled-components';
import {TimeSlotContext} from '../context';

const TimeSlotCell = styled.td`
  background: #FEFDFA;
  border: solid 1px #aaa;
  vertical-align: top;
  padding: 5px;
  white-space: nowrap;
  word-wrap: break-word;
  @media only screen and (max-device-width : 480px) {
    white-space: normal;
  }
`;

const TimeSlot = ({timeSlot, invitee}) => {
  const {display, items} = timeSlot;
  return (
    <TimeSlotContext.Provider value={timeSlot}>
      <tr>
        <TimeSlotCell
          rowSpan={items.length + 1}
        >
          {display}
        </TimeSlotCell>
      </tr>
      {items.map(item => <Item
        key={item.id}
        item={item}
        // TODO: We don't need invitee, remove it?
        invitee={invitee}
        colSpan={1}
      />)}
    </TimeSlotContext.Provider>
  );
};

export default TimeSlot;
