import React from 'react';
import styled from 'styled-components';

export const FieldContainer = styled.div`
  margin-bottom: 10px;
`;

export const FieldLabel = styled.label`
  display: block;
  margin-bottom: 5px;
`;

export const FieldInput = styled.input`
  width: 100%;
  box-sizing: border-box;
  height: 32px !important;
  margin: 0 !important;
`;

export const FieldInputMultiline = styled.textarea`
  width: 100%;
  box-sizing: border-box;
  margin: 0 !important;
`;

export const MarkContainer = styled.span`
  margin-left: 5px;
  color: ${props => props.color};
`;

export const RequiredMark = () => <MarkContainer color="red">*</MarkContainer>;

export const ShowMark = () => <MarkContainer color="blue">Δ</MarkContainer>;

export const ErrorMessage = styled.div`
  color: red;
  font-size: 0.8em;
  line-height: 1em;
  margin: 3px 0;
`;

export const Error = ({children}) => {
  return children ? <ErrorMessage>{children}</ErrorMessage> : null;
};

export const Field = ({id, type = "text", rows, required, show, label, inputRef, error, onEnter, ...rest}) => {
  const onKeyPress = event => {
    if (event.key === 'Enter') {
      onEnter();
    }
  }
  return (
    <FieldContainer>
      <FieldLabel htmlFor={id}>
        {label}
        {required && <RequiredMark />}
        {show && <ShowMark />}
      </FieldLabel>
      {rows ?
        <FieldInputMultiline
          id={id}
          ref={inputRef}
          rows={rows}
          {...rest}
        /> :
        <FieldInput
          id={id}
          type={type}
          ref={inputRef}
          {...rest}
          onKeyPress={onEnter ? onKeyPress : undefined}
        />
      }
      <Error>{error}</Error>
    </FieldContainer>
  )
};
