import query from '../../../lib/services/query';

const contactOwnerQuery = `
  mutation ContactMessageableOwner($input: MessageableOwnerContactInput!) {
    succeeded: contactMessageableOwner(input: $input)
  }
`;

const contactOwner = input => {
  return query({
    query: contactOwnerQuery,
    variables: {input}
  });
};

export default {
  contactOwner
};
