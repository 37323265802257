import query from '../../../lib/services/query';

const resendQuery = `
  mutation ResendSignupConfirmation($sheetId: ID!, $allegedEmail: String!) {
    succeded: resendSignupConfirmation(sheetId: $sheetId, allegedEmail: $allegedEmail)
  }
`;

const resend = variables => {
  return query({
    query: resendQuery,
    variables
  });
};

export default {
  resend
};
