import React, {useContext} from 'react';
import styled from 'styled-components';
import {SignupSheetContext} from '../../context';

const SocialLoginsContainer = styled.div`
  display: flex;
`;

const SocialLogin = styled.a`
  width: 140px;
  margin-bottom: 10px;
  margin-right: 5px;
`;

const SocialLogins = ({itemId}) => {
  const {authProviders} = useContext(SignupSheetContext);
  return (
    <SocialLoginsContainer>
      {authProviders.map(({name, properName, authorizePath}) => {
        const path = `${authorizePath}?state=item%7C${itemId}`;
        const cssClass = `social-login zocial ${name}`;
        return (
          <SocialLogin
            key={name}
            href={path}
            className={cssClass}
          >
            {properName}
          </SocialLogin>
        );
      }
    )}
    </SocialLoginsContainer>
  );
};

export default SocialLogins;
