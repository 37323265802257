import React, {useState, useContext, createRef, useEffect} from 'react';
import {ModalHeader, ModalBody, ModalButtonsContainer, ModalButton} from '../../../lib/components/Modal';
import getAlerter from '../../../lib/components/Alerter';
import {Field} from '../shared/Form';
import api from './api';

const ContactOwnerForm = ({onClose, messageableId, messageableType, name: initialName = '', contactInfo: initialContactInfo = '', title = 'Contact the Sheet Owner'}) => {
  const [sending, setSending] = useState(false);
  const [name, setName] = useState(initialName);
  const [contactInfo, setContactInfo] = useState(initialContactInfo);
  const [message, setMessage] = useState('');
  const [errors, setErrors] = useState({});
  const nameInputRef = createRef(null);
  const contactInfoInputRef = createRef(null);
  const messageInputRef = createRef(null);
  const [needsFocus, setNeedsFocus] = useState(true);
  const alerter = getAlerter();
  useEffect(() => {
    if (needsFocus) {
      if (!name || name.length === 0 || errors.name) {
        nameInputRef.current.focus();
      } else if (!contactInfo || contactInfo.length === 0 || errors.contactInfo) {
        contactInfoInputRef.current.focus();
      } else if (!message || message.length === 0 || errors.message) {
        messageInputRef.current.focus();
      }
      setNeedsFocus(false);
    }
  });
  const dismissErrors = () => {
    setErrors({});
  };
  const nameChanged = event => {
    dismissErrors()
    setName(event.target.value);
  };
  const contactInfoChanged = event => {
    dismissErrors();
    setContactInfo(event.target.value);
  };
  const messageChanged = event => {
    dismissErrors();
    setMessage(event.target.value);
  };

  const onOK = () => {
    setSending(true);
    const errs = {};
    if (name.trim().length === 0) {
      errs.name = 'Name is required';
    } else if (name.trim().length < 2) {
      errs.name = 'Name must be at least 2 characters';
    }
    if (contactInfo.trim().length === 0) {
      errs.contactInfo = 'Contact info is required';
    }
    if (message.trim().length === 0) {
      errs.message = 'A message is required';
    } else if (message.trim().length < 10) {
      errs.message = 'The message is too short; minimum 10 characters';
    } else if (message.trim().length > 2500) {
      errs.message = 'The message is too long; max is 2,500 characters';
    }
    if (Object.keys(errs).length > 0) {
      setErrors(errs);
      setNeedsFocus(true);
      setSending(false);
    } else {
      api.contactOwner({
        messageableId,
        messageableType,
        name,
        contactInfo,
        message
      })
      .then(() => {
        onClose();
        alerter.popSuccess('The message has been sent');
      })
      .catch(err => {
        alerter.popError(err, 're-send confirmation email');
      })
      .finally(() => {
        setSending(false);
      });
    }
  };
  return (
    <>
      <ModalHeader>{title}</ModalHeader>
      <ModalBody>
        <Field
          label="Your Name"
          inputRef={nameInputRef}
          value={name}
          onChange={nameChanged}
          error={errors.name}
          onEnter={onOK}
        />
        <Field
          label="How Can The Owner Reach You?"
          inputRef={contactInfoInputRef}
          value={contactInfo}
          onChange={contactInfoChanged}
          error={errors.contactInfo}
          onEnter={onOK}
        />
        <Field
          label="Message"
          inputRef={messageInputRef}
          rows={5}
          value={message}
          onChange={messageChanged}
          error={errors.message}
          onEnter={onOK}
        />
      </ModalBody>
      <ModalButtonsContainer>
        <ModalButton
          link
          onClick={onClose}
          disabled={sending}
        >
          Cancel
        </ModalButton>
        <ModalButton
          onClick={onOK}
          disabled={sending}
        >
          Send
        </ModalButton>
      </ModalButtonsContainer>
    </>
  );
};

export default ContactOwnerForm;
