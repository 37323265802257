import React, {useContext} from 'react';
import AdBlockDetect from 'react-ad-block-detect';
import Modal, {ModalHeader, ModalBody, ModalButtonsContainer, ModalButton} from '../../../lib/components/Modal';
import {SignupSheetContext} from '../../context';

const AdBlockModal = () => {
  const {sheet: {showAds, belongsToCurrentUser}} = useContext(SignupSheetContext);
  const show = showAds && !belongsToCurrentUser;
  return (
    <AdBlockDetect>
      <Modal
        show={show}
        onClose={() => {}}
      >
        <ModalHeader>Ad Blocker Detected</ModalHeader>
        <ModalBody>
          <p>It looks like you're using an ad blocker. </p>
          <p>To access this signup sheet, please disable it and refresh the page.</p>
        </ModalBody>
        <ModalButtonsContainer>
          <ModalButton onClick={() => window.location.reload()}>Refresh</ModalButton>
        </ModalButtonsContainer>
      </Modal>
    </AdBlockDetect>
  );
};

export default AdBlockModal;
