import React, {useState, useContext, createRef, useLayoutEffect} from 'react';
import SheetHeader from '../SheetHeader';
import styled from 'styled-components';
import {SignupSheetContext} from '../../context';
import Grid from '../Grid';
import ResendConfirmationEmail from '../ResendConfirmationEmail';
import {EventContext} from '../../context';
import Tip from '../../../lib/components/Tip';
import Button from '../../../lib/components/Button';
import {ResponsiveTopBannerAd, ResponsiveBottomBannerAd} from '../../../lib/components/Ad';
import {willHidePastDates} from '../helpers/pastDatesHelper';
import SpamMessage from './SpamMessage';

const Tabs = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 10px;
`;

const Tab = styled.div`
  font-size: 1.1em;
  color: ${({isActive}) => isActive ? '#fff' : '#e06d02'};
  text-shadow: none;
  background-color: ${({isActive}) => isActive ? '#3a3933' : undefined};
  margin-right: 2px;
  padding: 8px 12px;
  border-radius: 5px;
  outline: 0;
  cursor: ${({isActive}) => isActive ? undefined : 'pointer'};
  :hover {
    background-color: ${({isActive}) => isActive ? undefined : '#dededa'};
  }
`;

const BodyContainer = styled.div`
  margin-right: 10px;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const ResendConfirmationEmailButton = styled(Button)`
  margin-bottom: 10px;
`;

const SignupSheetBody = () => {
  const {sheet: {flaggedAsSpam, isTabbed, pastDatesOption, events, timeSlots, items, showAds}} = useContext(SignupSheetContext);
  const hidePastDates = willHidePastDates(pastDatesOption);
  const eventsToShow = hidePastDates
    ? events.filter(event => !event.isPast)
    : events;
  const hasGeneral = items.length > 0 || timeSlots.length > 0;
  const tabsCount = isTabbed ? eventsToShow.length + (hasGeneral ? 1 : 0) : 0;
  const showTabs = tabsCount > 1;
  const [activeEventId, setActiveEventId] = useState(!hasGeneral && eventsToShow.length > 0 && eventsToShow[0].id);
  const bodyContainerRef = createRef();

  if (flaggedAsSpam) {
    return <SpamMessage />;
  }

  const gridRefsMap = {};
  if (hasGeneral) {
    gridRefsMap[null] = createRef();
  }
  eventsToShow.forEach(({id}) => {
    gridRefsMap[id] = createRef();
  });
  const equalizeGrids = () => {
    // Tabs have only one grid; no need to equalize
    if (showTabs) {
      return;
    }
    const renderedGridRefs = Object.values(gridRefsMap).filter(ref => ref.current);
    const widths = renderedGridRefs.map(ref => ref.current.clientWidth);
    let width = Math.max.apply(Math, widths);
    width = width + 20;
    const workAreaWidth = bodyContainerRef.current.clientWidth;
    width = Math.min(width, workAreaWidth);
    renderedGridRefs.forEach(ref => {
      ref.current.width = width;
    });
  };
  useLayoutEffect(equalizeGrids);
  return (
    <BodyContainer
      ref={bodyContainerRef}
    >
      {showAds &&  <ResponsiveTopBannerAd />}
      <SheetHeader />
      {showTabs &&
        <Tabs>
          {hasGeneral &&
            <Tip
              content="Show slots for no particular date"
              placement="top"
            >
              <Tab
                isActive={!activeEventId}
                onClick={() => setActiveEventId(undefined)}
              >
                General
              </Tab>
            </Tip>
          }
          {eventsToShow.map(({id, caption}) => {
            return (
              <Tip
                key={id}
                content={`Show slots for ${caption}`}
                placement="top"
              >
                <Tab
                  isActive={activeEventId === id}
                  onClick={() => setActiveEventId(id)}
                >
                  {caption}
                </Tab>
              </Tip>
            );
          })}
        </Tabs>
      }
      <div>
        {hasGeneral &&
          <>
            <Grid
              show={!showTabs || !activeEventId}
              caption={!showTabs && eventsToShow.length > 0 && 'General'}
              items={items}
              timeSlots={timeSlots}
              ref={gridRefsMap[null]}
            />
          </>
        }
        {eventsToShow.map(event => {
          return (
            <EventContext.Provider key={event.id} value={event}>
              <Grid
                show={!showTabs || activeEventId === event.id}
                caption={!showTabs && event.caption}
                items={event.items}
                timeSlots={event.timeSlots}
                ref={gridRefsMap[event.id]}
              />
            </EventContext.Provider>
          );
        })}
        <ResendConfirmationEmail
          button={<ResendConfirmationEmailButton>Resend My Confirmation Email</ResendConfirmationEmailButton>}
        />
      </div>
      {showAds && <ResponsiveBottomBannerAd />}
    </BodyContainer>
  );
};

export default SignupSheetBody;
