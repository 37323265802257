import styled from 'styled-components';

const Link = styled.a`
  color: #0088cc;
  text-decoration: none;
  cursor: pointer;
  outline: 0;
  &:hover {
    color: #005580;
    text-decoration: underline;
  }
`;

export default Link;
