import React, {useState} from 'react';
import styled from 'styled-components';
import {motion, AnimatePresence} from 'framer-motion';

const CloseButton = styled.a`
  position: relative;
  top: -2px;
  right: -21px;
  font-size: 2em;
  line-height: 18px;
  float: right;
  font-weight: bold;
  color: #000000;
  text-shadow: 0 1px 0 #ffffff;
  opacity: 0.2;
  text-decoration: none;
  outline: 0;
  cursor: pointer;
  :hover {
    text-decoration: none;
    opacity: 0.4;
  }
`;

const StyledMotionDiv = styled(motion.div)`
  overflow: hidden;
`;

const AlertContainer = styled.div`
  padding: 8px 35px 8px 14px;
  margin-bottom: 8px;
  text-shadow: 0 1px 0 rgba(255,255,255,0.5);
  background-color: #fcf8e3;
  border: 1px solid #fbeed5;
  border-radius: 4px;
  background-color: ${({type}) => {
    switch(type) {
      case 'success':
        return '#dff0d8';
      case 'error':
        return '#f2dede';
      case 'info':
        return '#d9edf7';
    }
  }};
  border-color: ${({type}) => {
    switch(type) {
      case 'success':
        return '#d6e9c6';
      case 'error':
        return '#eed3d7';
      case 'info':
        return '#bce8f1';
    }
  }};
  color: ${({type}) => {
    switch(type) {
      case 'success':
        return '#468847';
      case 'error':
        return '#b94a48';
      case 'info':
        return '#3a87ad';
    }
  }};
`;

const Alert = ({type = 'info', canClose, children}) => {
  const [show, setShow] = useState(true);
  const onClose = () => {
    setShow(false);
  }
  return (
    <AnimatePresence initial={false}>
      {show &&
        <StyledMotionDiv
          initial={{height: 0}}
          animate={{height: 'auto'}}
          transition={{duration: 0.3}}
          exit={{height: 0}}
        >
          <AlertContainer type={type}>
            {canClose &&
              <CloseButton onClick={onClose} href="#">&times;</CloseButton>
            }
            {children}
          </AlertContainer>
        </StyledMotionDiv>
      }
    </AnimatePresence>
  );
}

export default Alert;
