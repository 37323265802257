import React from 'react';
import Modal from '../../../lib/components/Modal';
import ContactOwnerForm from './Form';

const ContactOwner = ({show, onClose, ...props}) => {
  return (
    <Modal
      show={show}
      onClose={onClose}
      minWidth={'min(calc(100vw - 20px), 400px);'}
    >
      <ContactOwnerForm
        onClose={onClose}
        {...props}
      />
    </Modal>
  )
}

export default ContactOwner;
