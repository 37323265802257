import {getHidePastDatesParam} from './queryStringHelper';

export const willHidePastDates = setting => {
  const param = getHidePastDatesParam();
  switch (setting) {
    case 'ALWAYS_HIDE':
      return true;
    case 'ALWAYS_SHOW':
      return false;
    case 'DEFAULT_HIDE':
      return param === '0' ? false : true;
    case 'DEFAULT_SHOW':
      return param  === '1' ? true : false;
  }
};
