import queryString from 'query-string';

export const getParams = () => {
  return queryString.parse(window.location.search)
}

export const getHidePastDatesParam = () => {
  return getParams()['hide_past_dates'];
}

export const getEmbedParam = () => {
  return getParams()['embed'];
}

export const setParam = (name, value, converter = value => value) => {
  const {url, query = {}} = queryString.parseUrl(window.location.href);
  query[name] = converter(value);
  const newUrl = new URL(url);
  newUrl.search = queryString.stringify(query);
  return newUrl.toString();
};

export const addHidePastDatesParam = hide => {
  return setParam('hide_past_dates', hide, value => value ? 1 : 0);
};
