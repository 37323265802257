import React from 'react';
import Modal from '../../../lib/components/Modal';
import SignupDetailsForm from './Form';

const SignupDetails = ({show, onClose, ...props}) => {
  return (
    <Modal
      show={show}
      onClose={onClose}
    >
      <SignupDetailsForm
        onClose={onClose}
        {...props}
      />
    </Modal>
  )
}

export default SignupDetails;
