import React, {useContext, useState, createRef, useEffect} from 'react';
import styled from 'styled-components';
import {ModalHeader, ModalBody, ModalButtonsContainer, ModalButton} from '../../../lib/components/Modal';
import {Field, Error, RequiredMark, ShowMark} from '../shared/Form';
import SocialLogins from './SocialLogins';
import {SignupSheetContext} from '../../context';
import {MdClose} from 'react-icons/md';

const Legend = styled.div`
  display: flex;
  width: 100%;
  div {
    margin-right: 20px;
  }
  margin-bottom: 10px;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
`;

const SocialLoginsSection = styled.section`
  padding: 0 15px;
`;

const GeneralErrorContainer = styled.div`
  margin-bottom: 10px;
`;

const CancelIcon = styled(MdClose)`
  cursor: pointer;
  outline: 0;
  margin-left: 20px;
  color: #333;
  border-radius: 3px;
  box-sizing: border-box;
  &:hover {
    color: #888;
    border: 1px solid #aaa;
    transition: 0.2s;
  }
`;

const SignupDetailsForm = ({itemId, data, onSubmit, errors, changeField, changeExtraInfo, disableSocialSignup, onClose}) => {
  const {sheet: {extraInfos, hideNames}, invitee} = useContext(SignupSheetContext);
  const nameInputRef = createRef(null);
  useEffect(() => {
    nameInputRef.current.focus();
    nameInputRef.current.select();
  }, []);
  const showSocialLogins = !disableSocialSignup && extraInfos.length === 0 && !invitee;
  const headerValue = extraInfos.length > 0
    ? 'We Need Some Additional Information'
    : `We Need To Know ${invitee ? 'Your Name' : 'Who You Are'}`;

  return (
    <>
      <ModalHeader>
        {headerValue}
        <CancelIcon onClick={onClose} size={22} />
      </ModalHeader>
      {showSocialLogins &&
        <SocialLoginsSection>
          <h3>Please confirm with a social account:</h3>
          <SocialLogins itemId={itemId} />
          <h3>Or enter the following:</h3>
        </SocialLoginsSection>
      }
      <ModalBody>
        <Field
          label="Name"
          required
          show={!hideNames}
          inputRef={nameInputRef}
          value={data.name}
          onChange={changeField('name')}
          error={errors.name}
          onEnter={onSubmit}
        />
        {!invitee &&
          <Field
            label="Email"
            required
            type="email"
            value={data.email}
            onChange={changeField('email')}
            error={errors.email}
            onEnter={onSubmit}
          />
        }
        {extraInfos.map(({id, name, required, show}) => {
          return (
            <Field
              key={`signup-${id}`}
              label={name}
              required={required}
              show={show}
              value={data.extraInfo[id]}
              onChange={changeExtraInfo(id)}
              error={errors[id]}
              onEnter={onSubmit}
            />
          );
        })}
        <Legend>
          <div><RequiredMark /> required</div>
          <div><ShowMark /> visible to others</div>
        </Legend>
        {Object.keys(errors).length > 0 &&
          <GeneralErrorContainer>
            <Error>Oops, please correct the indicated problems and try again.</Error>
          </GeneralErrorContainer>
        }
        <ModalButtonsContainer>
          <ModalButton
            link
            onClick={onClose}
          >
            Cancel
          </ModalButton>
          <ModalButton
            onClick={onSubmit}
          >
            OK
          </ModalButton>
        </ModalButtonsContainer>
      </ModalBody>
    </>
  );
};

export default SignupDetailsForm;
