import React from 'react';
import {Helmet} from "react-helmet";

const Meta = () => {
  return (
    <Helmet>
    </Helmet>
  );
};

export default Meta;
