import {postJSON} from './client';
import ValidationError from './ValidationError';

const query = payload => {
  return postJSON('/graphql', payload)
  .then(response => {
    if (response.errors) {
      const message = response.errors.map(({message}) => message).join(' ');
      throw new ValidationError(message);
    }
    return response.data;
  });
};

export default query;
