import React, {useContext, useState} from 'react';
import {SignupSheetContext, SignupDetailsContext} from '../../context';
import {isValidEmail} from '../../../lib/services/emailHelper';
import SignupDetails from './index';

const SignupDetailsProvider = ({children}) => {
  const {sheet: {extraInfos, disableSocialSignup}, invitee, currentUser} = useContext(SignupSheetContext);
  const [show, setShow] = useState(false);
  const [onSignUp, setOnSignUp] = useState();
  const [itemId, setItemId] = useState();
  const [succeeded, setSucceeded] = useState(false);
  const [data, setData] = useState({
    email: invitee ? invitee.email : currentUser ? currentUser.email : '',
    name: invitee ? invitee.name : currentUser ? currentUser.fullName : '',
    extraInfo: extraInfos.reduce((result, {id}) => {
      result[id] = ''
      return result;
    }, {})
  });
  const [errors, setErrors] = useState({});
  const validate = () => {
    const errs = {};
    if (!data.email || data.email.trim().length === 0) {
      errs.email = 'Email is required';
    } else if (!isValidEmail(data.email)) {
      errs.email = 'Not a valid email address';
    }
    if (!data.name || data.name.trim().length === 0) {
      errs.name = 'Name is required';
    }
    extraInfos.forEach(({id, name, required}) => {
      if (required && (!data.extraInfo[id] || data.extraInfo[id].trim().length === 0)) {
        errs[id] = `${name} is required`
      }
    });
    setErrors(errs);
    return errs;
  };
  const isValid = () => {
    const errs = validate();
    return Object.keys(errs).length === 0;
  }
  const submit = () => {
    if (isValid()) {
      onSignUp(data);
    }
  };
  const clearErrors = () => {
    setErrors({});
  }
  const changeField = field => event => {
    clearErrors();
    const value = event.target.value;
    setData(data => {
      return {
        ...data,
        [field]: value
      }
    });
  };
  const changeExtraInfo = id => event => {
    clearErrors();
    const value = event.target.value;
    setData(data => {
      return {
        ...data,
        extraInfo: {
          ...data.extraInfo,
          [id]: value
        }
      }
    });
  };
  const openModal = ({itemId, onSignUp}) => {
    setItemId(itemId);
    setOnSignUp(() => onSignUp);
    setShow(true);
  }
  const closeModal = () => {
    clearErrors();
    setShow(false);
  };
  const value = {
    modal: {
      open: openModal,
      close: closeModal,
      isOpen: show
    },
    data,
    itemId,
    setSucceeded,
    succeeded,
    isValid,
  };
  return (
    <SignupDetailsContext.Provider value={value}>
      {children}
      <SignupDetails
        show={show}
        onClose={closeModal}
        itemId={itemId}
        data={data}
        changeField={changeField}
        changeExtraInfo={changeExtraInfo}
        disableSocialSignup={disableSocialSignup}
        onSubmit={submit}
        errors={errors}
      />
    </SignupDetailsContext.Provider>
  )
}

export default SignupDetailsProvider;
