import query from '../../../lib/services/query';

const cloneSheetQuery = `
  mutation CloneSheet($sheetId: ID!) {
    newSheetPath: cloneSheet(sheetId: $sheetId)
  }
`;

const cloneSheet = sheetId => {
  return query({
    query: cloneSheetQuery,
    variables: {sheetId}
  });
};

const sendSignupsQuery = `
  mutation SendSignups($sheetId: ID!, $allegedEmail: String!) {
    sendSignups(sheetId: $sheetId, allegedEmail: $allegedEmail)
  }
`;

const sendSignups = (sheetId, allegedEmail) => {
  return query({
    query: sendSignupsQuery,
    variables: {
      sheetId,
      allegedEmail
    }
  });
};

const flagAsSpamQuery = `
  mutation FlagSheetAsSpam($sheetId: ID!, $isSpam: Boolean!) {
    success: flagSheetAsSpam(sheetId: $sheetId, isSpam: $isSpam)
  }
`;

const flagAsSpam = (sheetId, isSpam) => {
  return query({
    query: flagAsSpamQuery,
    variables: {
      sheetId,
      isSpam
    }
  })
};

export default {
  cloneSheet,
  sendSignups,
  flagAsSpam
};
