import styled from 'styled-components';

const Button = styled.button`
  margin: 5px 0 0;
  width: ${({fullWidth, width}) => width ? width : fullWidth ? '100%' : undefined};
  min-width: 100px;
  white-space: nowrap;
  box-sizing: border-box;
  display: inline-block;
  padding: 4px 10px 4px;
  font-size: 18px;
  line-height: 22px;
  outline: 0;
  color: ${({link}) => link ? '#0074cc' : '#3a3933'};
  text-align: center;
  text-shadow: ${({link}) => link ? undefined : '0 1px 1px rgba(255, 255, 255, 0.75)'};
  vertical-align: middle;
  cursor: pointer;
  background-color: ${({link}) => link ? 'transparent' : '#f5f5f5'};
  background-image: ${({link}) => link ? undefined : '-webkit-linear-gradient(top, #ffffff, #e6e6e6)'};
  background-repeat: repeat-x;
  border: ${({link}) => link ? 'none' : '1px solid #ccc'};
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  border-bottom-color: #b3b3b3;
  border-radius: 4px;
  box-shadow: ${({link}) => link ? undefined : 'inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05)'};
  &:hover {
    color: ${({link}) => link ? '#0074cc' : '#3a3933'};
    text-decoration: none;
    background-color: #e6e6e6;
    background-position: 0 -15px;
    transition: background-position 0.1s linear;
  }
  &:disabled {
    color: #bbb;
    background-image: none;
    cursor: not-allowed;
  }
  @media print {
    display: none;
  }
`;

export default Button;
