import doQuery from '../../../lib/services/query';

const signupResponseFields = `
id
email
name(token: $token)
isExtra
userId
isPending
mightBeMine(token: $token)
extraFields(token: $token) {
  id
  name
  value
}`;

const signupPublicQuery = `
  mutation SignupPublic($signupData: SignupPublicInput!, $token: String!) {
    result: signupPublic(input: $signupData) {
      signup {
        ${signupResponseFields}
      }
      confirmationSent
    }
  }
`;

const signUpPublic = (signupData, token) => {
  return signupUp(signupData, signupPublicQuery, token);
};

const signupInviteeQuery = `
  mutation SignupInvitee($signupData: SignupInviteeInput!, $token: String!) {
    result: signupInvitee(input: $signupData) {
      ${signupResponseFields}
    }
  }
`;

const signUpInvitee = (signupData, token) => {
  return signupUp(signupData, signupInviteeQuery, token);
};

const signupUp = (signupData, query, token) => {
  return doQuery({
    query,
    variables: {
      signupData,
      token
    }
  });
};

const cancelSignupQuery = `
  mutation CancelSignup($cancelSignupData: CancelSignupInput!) {
    result: cancelSignup(input: $cancelSignupData)
  }
`;

const cancelSignup = cancelSignupData => {
  return doQuery({
    query: cancelSignupQuery,
    variables: {
      cancelSignupData
    }
  });
};

export default {
  signUpPublic,
  signUpInvitee,
  cancelSignup
};
