import React, {Fragment, useState, useContext} from 'react';
import {SignupSheetContext, AllegedEmailContext} from '../../context';
import getAlerter from '../../../lib/components/Alerter';
import api from './api';

const ResendConfirmationEmail = ({button, wrapper: Wrapper = Fragment}) => {
  const {sheet: {id: sheetId, shouldOfferToResendConfirmation}, invitee} = useContext(SignupSheetContext);
  const [isRequesting, setIsRequesting] = useState(false);
  const allegedEmailContext = useContext(AllegedEmailContext);
  const alerter = getAlerter();
  if (invitee || !shouldOfferToResendConfirmation) {
    return null;
  }
  const request = () => {
    setIsRequesting(true);
    allegedEmailContext.modal.open({
      onConfirm: resend,
      instructions: (
        <div>
          <p>If resending doesn't work, please check your email spam folder. You might also check if your company is blocking Slotted emails.</p>
          <p>If all else fails, the owner of this sheet can manually confirm your signups.</p>
        </div>
      )
    });
  }
  const doneResend = () => {
    allegedEmailContext.modal.close();
    setIsRequesting(false);
  }
  const resend = allegedEmail => {
    return api.resend({
      sheetId,
      allegedEmail
    })
    .then(() => {
      alerter.popSuccess(`A confirmation email was re-sent to ${allegedEmail}`);
    })
    .catch(err => {
      alerter.popError(err, 're-send confirmation email')
    })
    .finally(doneResend)
  };
  return (
    <Wrapper>
      {React.cloneElement(button, {
        onClick: request,
        disabled: isRequesting
      })}
    </Wrapper>
  );
};

export default ResendConfirmationEmail;
