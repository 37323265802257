import React, {useState, useContext} from 'react';
import {SignupSheetContext, AllegedEmailContext, SignupDetailsContext} from '../../context';
import ResendConfirmationEmail from '../ResendConfirmationEmail';
import ContactOwner from '../ContactOwner';
import styled from 'styled-components';
import moment from 'moment-timezone';
import subscriberIndicator from '../../../images/dollar_sign_small.png';
import Tip from '../../../lib/components/Tip';
import Link from '../../../lib/components/Link';
import CopyToClipboardMenuItem from '../../../lib/components/CopyToClipboardMenuItem';
import getAlerter from '../../../lib/components/Alerter';
import api from './api';
import {addHidePastDatesParam, getEmbedParam} from '../helpers/queryStringHelper';
import {willHidePastDates} from '../helpers/pastDatesHelper';
import {MdEdit, MdPrint, MdMail, MdEvent, MdContentCopy, MdFlag} from 'react-icons/md';
import {ResponsiveSidebarAd} from '../../../lib/components/Ad';
import MenuItem from '../../../lib/components/MenuItem';

const Menu = styled.aside`
  min-width: 200px;
  max-width: 350px;
  @media print {
    display: none;
  }
`;

const Panel = styled.div`
  border-radius: 5px;
  border: solid 1px #aaa;
  padding: 10px;
  margin-bottom: 10px;
  :before, :after {
    display: table;
  }
`;

const SolidLightPanel = styled(Panel)`
  background: -webkit-linear-gradient(top, #fefdfa 0%, #f7f5f1 46%, #f2f1ee 100%);
`;

const MenuList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const MenuListItem = styled.li`
  line-height: 22px;
`;

const MenuHeading = styled.h2`
  font-size: 24px;
  font-weight: bold !important;
  line-height: 20px !important;
  margin: 0 0 7px;
  font-family: inherit;
  color: inherit;
  text-rendering: optimizelegibility;
`;

const EmText = styled.span`
  color: #e06d02;
  font-style: italic;
`;

const CopyToClipboardContainer = styled.div`
  cursor: pointer;
  outline: 0;
`;

const UrlTextbox = styled.textarea`
  width: 100%;
  box-sizing: border-box;
`;

const AdminMenu = () => {
  const { sheet: {id, flaggedAsSpam, user: { displayName, createdAt, sheetsCount, isSubscribed, adminPath } } } = useContext(SignupSheetContext);
  const alerter = getAlerter();

  const flagAsSpam = async () => {
    const { success } = await api.flagAsSpam(id, !flaggedAsSpam);
    if (success) {
      alerter.popSuccess(`${flaggedAsSpam ? 'Unflagged' : 'Flagged'} as spam`);
    } else {
      alerter.popError(`Failed to ${flaggedAsSpam ? 'un' : ''}flag as spam`);
    }
  };

  return (
    <SolidLightPanel>
      <MenuHeading>Admin</MenuHeading>
      <MenuList>
        <MenuListItem>
          <Link href={adminPath}>{displayName}</Link> ~ {moment(new Date(createdAt)).format('M/D/YY')} ~ {sheetsCount} ~ {isSubscribed &&
              <img
                src={subscriberIndicator} alt="Dollar sign small"
                width="19"
                height="18"
              />
            }
        </MenuListItem>
        <MenuListItem>
          <Link href="#"
            onClick={flagAsSpam}
          >
            <MenuItem icon={MdFlag} label={flaggedAsSpam ? 'Unflag as Spam' : 'Flag as Spam'} />
          </Link>
        </MenuListItem>
      </MenuList>
    </SolidLightPanel>
  );
};

const SheetMenu = () => {
  if (getEmbedParam() === '1') {
    return null;
  }
  const {currentUser, invitee, sheet: {id, user, shouldOfferToSendSignups, allowContactOwner, canClone, editPath, signupUrl, isEditableByCurrentUser, pastDatesOption, isPublic, showAds, accessLevel}} = useContext(SignupSheetContext);
  const [resendingSignups, setResendingSignups] = useState(false);
  const [contactingOwner, setContactingOwner] = useState(false);
  const allegedEmailContext = useContext(AllegedEmailContext);
  const hidingPastDates = willHidePastDates(pastDatesOption);
  const allowShowHidePastDates = pastDatesOption.startsWith('DEFAULT');
  const showAdmin = currentUser && currentUser.isAdmin && user;
  const alerter = getAlerter();

  const {data: signupDetailsData} = useContext(SignupDetailsContext);

  const print = () => {
    window.print();
  }
  const cloneSheet = () => {
    return api.cloneSheet(id)
    .then(({newSheetPath}) => {
      window.location = newSheetPath;
    })
    .catch(err => {
      alerter.popError(err, 'cloning sheet');
    });
  }
  const sendSignups = allegedEmail => {
    return api.sendSignups(id, allegedEmail)
    .then(() => {
      alerter.popSuccess(`If signups were found, an email was sent to ${allegedEmail}`);
    })
    .catch(err => {
      alerter.popError(err, 'sending signups');
    })
    .finally(() => {
      setResendingSignups(false);
      allegedEmailContext.modal.close();
    });
  }
  const requestSendSignups = () => {
    setResendingSignups(true);
    allegedEmailContext.modal.open({
      onConfirm: sendSignups
    });
  }
  const requestContactOwner = () => {
    setContactingOwner(true);
  };
  const doneContactingOwner = () => {
    setContactingOwner(false);
  }
  return (
    <Menu>
      <div>
        {showAdmin && <AdminMenu />}
        <SolidLightPanel>
          <MenuList>
            {isEditableByCurrentUser &&
              <MenuListItem>
                <Link href={editPath}>
                  <MenuItem
                    icon={MdEdit}
                    label="Edit This Sheet"
                  />
                </Link>
              </MenuListItem>
            }
            <MenuListItem>
              <Link onClick={print}>
                <MenuItem
                  icon={MdPrint}
                  label="Print This Sheet"
                />
              </Link>
            </MenuListItem>
            <ResendConfirmationEmail
              wrapper={MenuListItem}
              button={
                <Link href="#">
                  <MenuItem icon={MdMail} label="Resend My Confirmation Email" />
                </Link>
              }
            />
            {!invitee && shouldOfferToSendSignups &&
              <MenuListItem>
                <Link href="#"
                  onClick={requestSendSignups}
                  disabled={resendingSignups}
                >
                  <MenuItem icon={MdMail} label="Email Me My Signups" />
                </Link>
              </MenuListItem>
            }
            {allowContactOwner &&
              <MenuListItem>
                <Link href="#"
                  onClick={requestContactOwner}
                >
                  <MenuItem icon={MdMail} label="Contact Sheet Owner" />
                </Link>
              </MenuListItem>
            }
            {canClone &&
              <MenuListItem>
                <Tip
                  content={(
                    <span>
                      Make a copy of this sheet.<br/><br/>After you clone it, find the <EmText>Claim The Sheet</EmText> menu in the copy to claim it for your own.
                    </span>
                  )}
                >
                  <Link
                    rel="nofollow"
                    href="#"
                    onClick={cloneSheet}
                  >
                    <MenuItem icon={MdContentCopy} label="Clone This Sheet" />
                  </Link>
                </Tip>
              </MenuListItem>
            }
            {allowShowHidePastDates &&
              <MenuListItem>
                <Link href={addHidePastDatesParam(!hidingPastDates)}>
                  <MenuItem icon={MdEvent} label={`${hidingPastDates ? 'Show' : 'Hide'} Past Dates`} />
                </Link>
              </MenuListItem>
            }
          </MenuList>
        </SolidLightPanel>
        {isPublic && showAds && <ResponsiveSidebarAd />}
        {accessLevel === 'PublicListed' &&
          <SolidLightPanel>
            <MenuHeading>Share This Sheet</MenuHeading>
            <p>
              If you know of someone who might want to sign up on this sheet, please share this link.
            </p>
            <UrlTextbox
              value={signupUrl}
              readOnly
            />
            <CopyToClipboardMenuItem
              value={signupUrl}
              label="Copy to Clipboard"
              tip="Copy this sheet's public URL to your computer's clipboard"
            />
          </SolidLightPanel>
        }
        {showAds && <ResponsiveSidebarAd />}
      </div>
      <ContactOwner
        show={contactingOwner}
        onClose={doneContactingOwner}
        messageableId={id}
        messageableType="Sheet"
        name={(signupDetailsData && signupDetailsData.name) || (invitee && invitee.name) || ''}
        contactInfo={(signupDetailsData && signupDetailsData.email) || (invitee && invitee.email) || ''}
      />
    </Menu>
  );
};

export default SheetMenu;
