import React, {useState, createRef, useEffect} from 'react';
import {ModalHeader, ModalBody, ModalButtonsContainer, ModalButton} from '../../../lib/components/Modal';
import {isValidEmail} from '../../../lib/services/emailHelper';
import {Field} from '../shared/Form';

const AllegedEmailForm = ({onConfirm, onClose, instructions, value, setValue, error, setError}) => {
  const [confirming, setConfirming] = useState(false);
  const emailInputRef = createRef(null);
  useEffect(() => {
    emailInputRef.current.focus();
  }, []);
  const emailChanged = event => {
    setValue(event.target.value)
  };
  const onOK = () => {
    setConfirming(true);
    if (value.trim().length === 0) {
      setError('Email is required');
      setConfirming(false);
    } else if (!isValidEmail(value)) {
      setError('Not a valid email address');
      setConfirming(false);
    } else {
      onConfirm(value)
      .finally(() => {
        setConfirming(false);
      });
    }
  };
  return (
    <>
      <ModalHeader>Please Confirm Your Email</ModalHeader>
      <ModalBody>
        <Field
          label="Email"
          type="email"
          inputRef={emailInputRef}
          value={value}
          onChange={emailChanged}
          error={error}
          onEnter={onOK}
        />
        {instructions}
      </ModalBody>
      <ModalButtonsContainer>
        <ModalButton
          link
          onClick={onClose}
          disabled={confirming}
        >
          Cancel
        </ModalButton>
        <ModalButton
          onClick={onOK}
          disabled={confirming}
        >
          OK
        </ModalButton>
      </ModalButtonsContainer>
    </>
  );
};

export default AllegedEmailForm;
