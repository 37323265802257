import React, {forwardRef} from 'react';
import styled from 'styled-components';
import TimeSlot from './TimeSlot';
import Item from './Item';

const GridTable = styled.table`
  margin: 0 0 10px 0;
  border-collapse: collapse;
  border: solid 1px #aaa;
  max-width: 100%;
  background-color: transparent;
  border-spacing: 0;
  td {
    border: solid 1px #aaa;
    vertical-align: top;
    padding: 5px;
    word-wrap: break-word;
  }
  @media print {
    page-break-inside: avoid;
  }
`;

const Caption = styled.caption`
  text-align:left;
  font-size: 1.4em;
  line-height: 1.25em;
  margin: 0 0 5px 0;
`;

const Grid = forwardRef(({show, caption, timeSlots = [], items = []}, ref) => {
  if (!show) {
    return null;
  }
  return (
    <GridTable
      ref={ref}
    >
      {caption &&
        <Caption>{caption}</Caption>
      }
      <tbody>
        {items.map(item => <Item
          key={item.id}
          item={item}
          colSpan={2}
        />)}
        {timeSlots.map(timeSlot => <TimeSlot
          key={timeSlot.id}
          timeSlot={timeSlot}
        />)}
      </tbody>
    </GridTable>
  );
});

export default Grid;
