import React, {useContext, useState} from 'react';
import Signups from './Signups';
import {SignupSheetContext, SignupDetailsContext, AllegedEmailContext} from '../../context';
import styled from 'styled-components';
import api from './api';
import getAlerter from '../../../lib/components/Alerter';
import ItemName from './ItemName';

const ItemRow = styled.tr`
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
  @media print {
    height: 24px;
  }
`;

const Item = React.memo(({colSpan, item, allegedEmailContext, signupDetailsContext}) => {
  const {id, name, icalPath, isForDateTimeSlot, signups: initialSignups = []} = item;
  const {sheet: {token, belongsToCurrentUser: sheetBelongsToCurrentUser, extraInfos}, invitee, addedSignups, cancelledSignupIds, signupAdded, signupCancelled} = useContext(SignupSheetContext);
  const [signingUp, setSigningUp] = useState(false);

  // Apply added and cancelled to get to the current state.
  // NOTE: cancelledSignupIds should never be undefined, but for some reason it is sometimes in Chrome for Windows, so we guard for that.
  const cancelledIds = (cancelledSignupIds && cancelledSignupIds[item.id]) || new Set();
  const added = (addedSignups && addedSignups[item.id]) || [];
  const signups = [
    ...initialSignups,
    ...added
  ].filter(({id}) => !cancelledIds.has(id))

  const openOnThisItem = signupDetailsContext.modal.isOpen && signupDetailsContext.itemId === id;

  const showNameColumn = (name && name.length > 0) || isForDateTimeSlot;

  const alerter = getAlerter();

  const handleError = action => err => {
    alerter.popError(err, action);
  };

  const onSignup = evt => {
    const needsDetails = evt.ctrlKey || evt.metaKey || extraInfos.length > 0 || !signupDetailsContext.succeeded || !signupDetailsContext.isValid();
    if (needsDetails) {
      signupDetailsContext.modal.open({
        onSignUp: data => {
          signupDetailsContext.modal.close();
          signUp(data);
        },
        itemId: id
      });
    } else {
      signUp(signupDetailsContext.data);
    }
  };
  const requestToCancelSignup = signup => {
    const isNonPublic = sheetBelongsToCurrentUser || invitee || signup.isForCurrentUser;
    if (isNonPublic) {
      cancelSignupNonPublic(signup);
    } else {
      allegedEmailContext.modal.open({
        onConfirm: cancelSignupPublic(signup)
      });
    }
  };
  const doneCancellingSignup = () => {
    allegedEmailContext.modal.close();
  };
  const signUpPublic = data => {
    const args = {
      itemId: id,
      ...data
    };
    return api.signUpPublic(args, token)
    .then(({result: {signup, confirmationSent}}) => {
      addSignup(signup);
      if (signup.isPending) {
        const message = confirmationSent
          ? (
            <span>Your signup is pending. You should shortly receive an email. Please click the <strong>confirm link</strong> in that email to verify your identity.</span>
          )
          : 'Your signup is pending until confirmed. Check your email.';
        alerter.popSuccess(message);
      }
    })
  };
  const signUpInvitee = ({name, extraInfo}) => {
    const args = {
      itemId: id,
      inviteeId: invitee.id,
      name,
      extraInfo
    };
    return api.signUpInvitee(args, token)
    .then(({result}) => {
      addSignup(result);
    });
  };
  const addSignup = signup => {
    signupDetailsContext.setSucceeded(true);
    signupAdded(item.id, signup);
  };
  const signUp = ({extraInfo, ...rest}) => {
    const variables = {
      ...rest,
      extraInfo: Object.keys(extraInfo).map(id => ({
        extraInfoId: id,
        value: extraInfo[id]
      }))
    };
    setSigningUp(true);
    (invitee ? signUpInvitee : signUpPublic)(variables)
    .catch(handleError('sign up'))
    .finally(() => {
      setSigningUp(false);
    });;
  };
  const cancelSignupNonPublic = signup => {
    const args = {
      itemId: id,
      signupId: signup.id,
      allegedEmail: invitee ? invitee.email : undefined,
      facebookUserId: invitee ? invitee.facebookUserId : undefined,
      userId: signup.userId
    };
    api.cancelSignup(args)
    .then(() => {
      removeSignup(signup.id);
    })
    .catch(handleError('cancel signup'))
    .finally(() => {
      doneCancellingSignup();
    });
  };
  const cancelSignupPublic = signup => allegedEmail => {
    const args = {
      itemId: id,
      signupId: signup.id,
      allegedEmail
    };
    return api.cancelSignup(args)
    .then(() => {
      removeSignup(signup.id);
    })
    .catch(handleError('cancel signup'))
    .finally(() => {
      doneCancellingSignup();
    });;
  };
  const removeSignup = signupId => {
    signupCancelled(item.id, signupId);
  }
  return (
    <ItemRow
      key={id}
    >
      {showNameColumn &&
        <ItemName
          item={item}
          icalPath={icalPath}
          colSpan={colSpan}
        />
      }
      <Signups
        item={item}
        signups={signups}
        onSignup={onSignup}
        onCancel={requestToCancelSignup}
        disabled={openOnThisItem || signingUp}
        colSpan={showNameColumn ? null : colSpan + 1}
      />
    </ItemRow>
  );
}, (props, nextProps) => {
  // NOTE: We don't every contexts change to trigger updates.
  return props.item === nextProps.item && props.colSpan === nextProps.colSpan && props.signupDetailsContext.succeeded === nextProps.signupDetailsContext.succeeded;
});

// NOTE: This is an outer component so we can memoize the inner one for performance.
const ItemCommponent = props => {
  return (
    <Item
      allegedEmailContext={useContext(AllegedEmailContext)}
      signupDetailsContext={useContext(SignupDetailsContext)}
      {...props}
    />
  );
};

export default ItemCommponent;
