import React, {useEffect} from 'react';
import styled, {createGlobalStyle} from 'styled-components';
import AdBlockModal from './AdBlockModal';
import SheetMenu from '../SheetMenu';
import SignupSheetProvider from './Provider';
import SignupDetailsProvider from '../SignupDetails/Provider';
import AllegedEmailProvider from '../AllegedEmail/Provider';
import {ModalProvider, BaseModalBackground} from 'styled-react-modal'
import {AlertProvider} from '../../../lib/components/Alerter';
import SignupSheetBody from './Body';
import Heap from 'react-heap'
import ReactGA from 'react-ga';
import Meta from '../Meta';
import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';

const bugsnagClient = bugsnag({
  apiKey: '40c193b1cee9cddab6928e1a39f71c71',
  releaseStage: process.env.NODE_ENV,
  notifyReleaseStages: [
    'production'
  ]
});
bugsnagClient.use(bugsnagReact, React);
const ErrorBoundary = bugsnagClient.getPlugin('react');

const GlobalStyle = createGlobalStyle`
  @media print {
    body {
      background-image: none;
      background-color: transparent;
    }
    #topnav,
    #bottomnav {
      display: none;
    }
  }
`;

// Patch for Samsung Internet (Browser) V 7.2
// see: https://github.com/SamsungInternet/support/issues/56
if (navigator.userAgent.match(/SamsungBrowser\/7\.2/i)) {
  Function.prototype.ToString = function () { return this.toString(); }
}

const FadingBackground = styled(BaseModalBackground)`
  opacity: ${props => props.opacity};
  transition: opacity ease 300ms;
`;

const MainContainer = styled.section`
  margin-top: 0;
  display: flex;
  flex-direction: row;
  @media (max-width: 800px) {
    flex-direction: column;
  }
  justify-content: space-between;
`;

const UnknownError = () => {
  return (
    <div>
      Oops, there was an unexpected error. An admin has been notified. <strong>Please refresh the page.</strong>
    </div>
  )
}

const SignupSheet = ({signupSheet}) => {
  const isProduction = process.env.NODE_ENV === 'production';
  const {currentUser, sheet: {title}} = signupSheet;
  useEffect(() => {
    document.title = title;
  }, []);
  ReactGA.initialize('UA-29705176-1', {
    testMode: !isProduction,
    debug: !isProduction,
    gaOptions: {
      userId
    }
  });
  ReactGA.pageview(window.location.href);
  const {id: userId, email, fullName} = currentUser || {};
  bugsnagClient.user = currentUser;

  return (
    <ErrorBoundary FallbackComponent={UnknownError}>
      <Heap
        appId={isProduction ? '2614887160' : '1192558184'}
        userId={userId}
        userData={{email, fullName}}
      />
      <Meta
        title={title}
      />
      <AlertProvider>
        <SignupSheetProvider signupSheet={signupSheet}>
          <ModalProvider backgroundComponent={FadingBackground}>
            <SignupDetailsProvider>
              <AllegedEmailProvider>
                <GlobalStyle />
                <MainContainer>
                  <SignupSheetBody />
                  <SheetMenu />
                </MainContainer>
              </AllegedEmailProvider>
              <AdBlockModal />
            </SignupDetailsProvider>
          </ModalProvider>
        </SignupSheetProvider>
      </AlertProvider>
    </ErrorBoundary>
  );
}

export default SignupSheet;
