import React, {useState} from 'react';
import {AllegedEmailContext} from '../../context';
import AllegedEmail from './index';

const AllegedEmailProvider = ({children}) => {
  const [show, setShow] = useState(false);
  const [value, setValue] = useState('');
  const [error, setError] = useState();
  const [instructions, setInstructions] = useState('');
  const [onConfirm, setOnConfirm] = useState();
  const closeModal = () => {
    setShow(false);
  }
  const openModal = ({onConfirm, instructions}) => {
    setOnConfirm(() => onConfirm);
    setInstructions(instructions);
    setShow(true);
  };
  const contextValue = {
    modal: {
      open: openModal,
      close: closeModal
    }
  };
  const changeValue = val => {
    setValue(val);
    setError();
  };
  return (
    <AllegedEmailContext.Provider value={contextValue}>
      {children}
      <AllegedEmail
        show={show}
        onClose={closeModal}
        instructions={instructions}
        onConfirm={onConfirm}
        value={value}
        setValue={changeValue}
        error={error}
        setError={setError}
      />
    </AllegedEmailContext.Provider>
  )
}

export default AllegedEmailProvider;
