import React from 'react';
import AdSense from 'react-adsense';
import styled from 'styled-components';

const SidebarAdContainer = styled.div`
  background-color: #eaeae9;
  border: 1px solid #d8d7d5;
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 10px;
`;

const VerticleLinkAdContainer = styled(SidebarAdContainer)`
  padding: 10px 10px 5px 10px;
`;

const BannerAdContainer = styled.div`
  margin-bottom: 15px;
`;

const InFeedAdContainer = styled.div`
  margin: 5px 0;
`;

const Ad = ({style = {display: 'block'}, ...props}) => {
  return (
    <AdSense.Google
      client="ca-pub-9188121761055396"
      style={style}
      {...props}
    />
  );
};

const ResponsiveTopBannerAd = () => {
  return (
    <BannerAdContainer>
      <Ad
        slot="6133309663"
        format="horizontal"
      />
    </BannerAdContainer>
  );
};

const ResponsiveBottomBannerAd = () => {
  return (
    <BannerAdContainer>
      <Ad
        slot="5795768861"
        format="horizontal"
      />
    </BannerAdContainer>
  );
};

const ResponsiveSidebarAd = () => {
  return (
    <SidebarAdContainer>
      <Ad
        slot="8947175265"
      />
    </SidebarAdContainer>
  );
};

const VerticleLinkAd = () => {
  return (
    <VerticleLinkAdContainer>
      <Ad
        slot="4519988865"
        style={{display: 'inline-block', width: 200, height: 90}}
      />
    </VerticleLinkAdContainer>
  );
};

const InFeedAd = () => {
  return (
    <InFeedAdContainer>
      <Ad
        slot="2975692833"
        format="fluid"
      />
    </InFeedAdContainer>
  );
};

export {
  ResponsiveTopBannerAd,
  ResponsiveSidebarAd,
  VerticleLinkAd,
  ResponsiveBottomBannerAd,
  InFeedAd
};
