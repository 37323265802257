import React, {useState} from 'react';
import {SignupSheetContext} from '../../context';

const SignupSheetProvider = ({signupSheet, children}) => {
  const [addedSignups, setAddedSignups] = useState({});
  const [cancelledSignupIds, setCancelledSignupIds] = useState({});
  const signupAdded = (itemId, signup) => {
    setAddedSignups(prev => {
      const copy = Object.assign({}, prev);
      copy[itemId] = copy[itemId] || [];
      copy[itemId].push(signup);
      return copy;
    });
  };
  const signupCancelled = (itemId, signupId) => {
    setCancelledSignupIds(prev => {
      const copy = Object.assign({}, prev);
      copy[itemId] = copy[itemId] || new Set();
      copy[itemId].add(signupId);
      return copy;
    });
  };
  const value = {
    ...signupSheet,
    addedSignups,
    cancelledSignupIds,
    signupAdded,
    signupCancelled
  };
  return (
    <SignupSheetContext.Provider value={value}>
      {children}
    </SignupSheetContext.Provider>
  );
}

export default SignupSheetProvider;
