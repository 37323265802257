import React, {useContext, createRef} from 'react';
import Tip from '../../../lib/components/Tip';
import {SignupSheetContext, EventContext, TimeSlotContext} from '../../context';
import iCalImage from '../../../images/ical.gif';
import googleImage from '../../../images/google_g2.gif';
import queryString from 'query-string';
import moment from 'moment-timezone';
import styled from 'styled-components';

const CalendarLink = styled.a`
  margin-left: 5px;
  @media print {
    display:none !important;
  }
`;

const ItemNameCell = styled.td`
  background: #FEFDFA;
  min-width: 55px;
  border: solid 1px #aaa;
  vertical-align: top;
  padding: 5px;
  word-wrap: break-word;
`;

const ItemName = ({item: {name, eventSummary, isForDateTimeSlot}, icalPath, colSpan}) => {
  const {sheet: {title, description, hasTimeZone, signupUrl}} = useContext(SignupSheetContext);

  const gcalUrl = () => {
    const {location = ''} = useContext(EventContext);
    const {startDateTime, endDateTime} = useContext(TimeSlotContext);
    const dateFormat = 'YYYYMMDDTHHmmSS';
    const params = {
      action: 'TEMPLATE',
      text: eventSummary,
      dates: `${moment(startDateTime).format(dateFormat)}/${moment(endDateTime).format(dateFormat)}`,
      location,
      trp: true,
      sprop: [
        `website:${signupUrl}`,
        `name:${encodeURI(title)}`
      ]
    };
    return `https://calendar.google.com/calendar/render?${queryString.stringify(params)}`
  };

  // NOTE: Adding description dynamically to avoid bloating the page. Adding it to the URL instead of window.open to make sure we don't get blocked.
  const gcalLink = createRef();
  const clickGcalLink = e => {
    const encodedDescription = encodeURI(description);
    const url = `${e.currentTarget.href}&details=${encodedDescription}`;
    gcalLink.current.href = url;
  };

  return (
    <ItemNameCell colSpan={colSpan}>
      {name}
      {isForDateTimeSlot &&
        <>
          <Tip
            content="Click to put this on your calendar with an iCal file"
          >
            <CalendarLink
              href={icalPath}
            >
              <img src={iCalImage} size="30x14"/>
            </CalendarLink>
          </Tip>
          {hasTimeZone &&
            <Tip
              content="Click to put this on your Google calendar"
            >
              <CalendarLink
                ref={gcalLink}
                href={gcalUrl()}
                target="_blank"
                onClick={clickGcalLink}
              >
                <img src={googleImage} size="14x14"/>
              </CalendarLink>
            </Tip>
          }
        </>
      }
    </ItemNameCell>
  );
};

export default ItemName;
