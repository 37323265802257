import React from 'react';

const SpamMessage = () => {
  return (
    <div>
      <p><b>This sheet has been flagged as spam.</b></p>
      <p>
        Please refer to our <a href="/home/terms" target="blank">Terms of Use</a> for permissable uses of Slotted. Please
        contact use at <a href="mailto:customerservice@slotted.co">customerservice@slotted.co</a> if you believe it was
        flagged in error.
      </p>
    </div>
  );
};

export default SpamMessage;
