import React from 'react';
import Modal from '../../../lib/components/Modal';
import AllegedEmailForm from './Form';

const AllegedEmail = ({show, onClose, ...props}) => {
  return (
    <Modal
      show={show}
      onClose={onClose}
      maxWidth="500px"
    >
      <AllegedEmailForm
        onClose={onClose}
        {...props}
      />
    </Modal>
  );
}

export default AllegedEmail;
