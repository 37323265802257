import React, {useContext} from 'react';
import Signup from '../Signup';
import Tip from '../../../lib/components/Tip';
import Button from '../../../lib/components/Button';
import {SignupSheetContext} from '../../context';
import styled from 'styled-components';
import {motion, AnimatePresence} from 'framer-motion';

const slideDuration = 0.3;

const SignupsCell = styled.td`
  background: #FEFDFA;
  border: solid 1px #aaa;
  vertical-align: top;
  padding: 5px;
  word-wrap: break-word;
`;

const SignupsList = styled.ul`
  margin: 0px 0px -5px;
  list-style: none;
  padding: 0px;
`;

const SignupButtonMessage = styled.div`
  margin-top: 5px;
  text-align: center;
  font-size: 0.8em;
`;

const StyledMotionDiv = styled(motion.div)`
  overflow: hidden;
`;


const Signups = ({signups, item: {allowExtra, atleast}, onSignup, onCancel, colSpan, disabled}) => {
  const {sheet: {buttonText, extraButtonText}} = useContext(SignupSheetContext);
  const remainingSlots = Math.max(atleast - signups.length, 0);
  const moreRemainingSlots = remainingSlots - 1;
  return (
    <SignupsCell colSpan={colSpan}>
      <SignupsList>
        <AnimatePresence initial={false}>
          {signups.map(signup => {
            return (
              <StyledMotionDiv
                key={signup.id}
                initial={{height: 0}}
                animate={{height: 'auto'}}
                transition={{duration: slideDuration}}
                exit={{height: 0}}
              >
                <Signup
                  signup={signup}
                  onCancel={onCancel}
                />
              </StyledMotionDiv>
            );
          })}
        </AnimatePresence>
      </SignupsList>
      <AnimatePresence initial={false}>
        {remainingSlots > 0 &&
          <StyledMotionDiv
            initial={{height: 0}}
            animate={{height: 'auto'}}
            transition={{duration: slideDuration}}
            exit={{height: 0}}
          >
            <Tip content="Sign up for this slot">
              <Button
                onClick={onSignup}
                disabled={disabled}
                fullWidth
              >
                {disabled ? 'Signing up...' : buttonText}
              </Button>
            </Tip>
            <AnimatePresence initial={false}>
              {moreRemainingSlots > 0 &&
                <StyledMotionDiv
                  initial={{height: 0}}
                  animate={{height: 'auto'}}
                  transition={{duration: slideDuration}}
                  exit={{height: 0}}
                >
                  <SignupButtonMessage>
                    plus {moreRemainingSlots} more slot{moreRemainingSlots !== 1 && 's'}
                  </SignupButtonMessage>
                </StyledMotionDiv>
              }
            </AnimatePresence>
          </StyledMotionDiv>
        }
      </AnimatePresence>
      <AnimatePresence initial={false}>
        {remainingSlots === 0 && allowExtra &&
          <StyledMotionDiv
            initial={{height: 0}}
            animate={{height: 'auto'}}
            transition={{duration: slideDuration}}
            exit={{height: 0}}
          >
            <SignupButtonMessage>no slots open</SignupButtonMessage>
            <Tip content="This item has reached the requisite signups, but extra signups are allowed. Click to sign up">
              <Button
                onClick={onSignup}
                disabled={disabled}
                fullWidth
              >
                {disabled ? 'Signing up...' : extraButtonText}
              </Button>
            </Tip>
          </StyledMotionDiv>
        }
      </AnimatePresence>
    </SignupsCell>
  );
};

export default Signups;
