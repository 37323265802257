import React, {useContext, useState, useRef, useLayoutEffect} from 'react';
import {SignupSheetContext} from '../context';
import styled from 'styled-components';
import Notice from '../../lib/components/Notice';
import Link from '../../lib/components/Link';
import {FaChevronUp, FaChevronDown} from 'react-icons/fa';
import {motion, AnimatePresence} from 'framer-motion';

const Unprintable = styled.div`
  @media print {
    display: none;
  }
`;

const LogoutLink = styled.a`
  cursor: pointer;
  color: #4444CA;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

const Title = styled.h1`
  font-size: 36px;
  margin-bottom: 15px;
  margin-right: 10px;
  line-height: 38px;
  font-weight: normal;
  flex: 1;
`;

const ExpandCollapseLink = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 0.8em;
  cursor: pointer;
  margin-bottom: 10px;
`;

const DescriptionContainer = styled.div`
  flex: 1;
`;

const ExpandCollapseStyle = `
  margin-right: 5px;
`;

const CollapseIcon = styled(FaChevronUp)`${ExpandCollapseStyle}`;
const ExpandIcon = styled(FaChevronDown)`${ExpandCollapseStyle}`;

const StyledMotionDiv = styled(motion.div)`
  overflow: hidden;
`;

const SheetHeader = () => {
  const {sheet: {title, descriptionHtml, isFull, isEditableByCurrentUser, isClaimable, claimSheetPath}, invitee, logoutPath} = useContext(SignupSheetContext);
  const [collapsable, setCollapsable] = useState(false);
  const [descriptionExpanded, setDescriptionExpanded] = useState(true);
  const descriptionRef = useRef();
  const collapseDescription = () => {
    setDescriptionExpanded(false);
  }
  const expandDescription = () => {
    setDescriptionExpanded(true);
  }
  useLayoutEffect(() => {
    if (descriptionRef.current && descriptionRef.current.clientHeight > 250) {
      setCollapsable(true);
    }
  }, []);
  return (
    <header>
      <Unprintable>
        {isClaimable &&
          <Notice type="success">
            Is this your sheet? <Link href={claimSheetPath}>Claim it</Link> before somebody else does!
          </Notice>
        }
        {isFull &&
          <Notice type="error">
            <strong>NOTE</strong>: Sorry, this sheet is full. There are no remaining open slots.
          </Notice>
        }
        {isEditableByCurrentUser &&
          <Notice canClose>
            <span>
              <strong>NOTE</strong>: Because you have edit permission on this sheet, you might see information that is not visible to the public. To see the sheet exactly as the public sees it, please <LogoutLink href={logoutPath}>log out</LogoutLink>.
            </span>
          </Notice>
        }
      </Unprintable>
      {title &&
        <TitleContainer>
          <Title>{title}</Title>
          {collapsable &&
            <ExpandCollapseLink
              onClick={descriptionExpanded ? collapseDescription : expandDescription}
            >
              {descriptionExpanded &&
                <>
                  <CollapseIcon />
                  Hide Description
                </>
              }
              {!descriptionExpanded &&
                <>
                  <ExpandIcon />
                  Show Description
                </>
              }
            </ExpandCollapseLink>
          }
        </TitleContainer>
      }
      {descriptionHtml &&
        <>
          <AnimatePresence initial={false}>
            {descriptionExpanded &&
              <StyledMotionDiv
                initial={{height: 0}}
                animate={{height: 'auto'}}
                transition={{duration: 0.4}}
                exit={{height: 0}}
              >
                <DescriptionContainer
                  ref={descriptionRef}
                  dangerouslySetInnerHTML={{__html: descriptionHtml}}
                />
              </StyledMotionDiv>
            }
          </AnimatePresence>
        </>
      }
      {invitee && invitee.name &&
        <Unprintable>
          <p>Welcome <span>{invitee.name}!</span></p>
        </Unprintable>
      }
    </header>
  )
};

export default SheetHeader;
