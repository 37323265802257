import React, {useContext, forwardRef} from 'react';
import {SignupSheetContext} from '../context';
import styled from 'styled-components';
import Tip from '../../lib/components/Tip';
import {MdClose} from 'react-icons/md';

const SignupItem = styled.li`
  color: #fff;
  text-shadow: 1px 1px 0 ${props => props.isExtra ? '#402C56' : (props.isPending ? '#72523f' : '#000')};
  background-color: ${props => props.isExtra ? '#7044a3' : (props.isPending ? '#e77817' : '#3A3933')};
  border-radius: 5px;
  padding: 4px 6px 6px 10px;
  overflow: hidden;
  position: relative;
  white-space: normal;
  margin: 0 0 5px 0;
  @media print {
    border: 1px solid #ccc;
  }
`;

const ExtraFieldsList = styled.ul`
  margin: 0;
  list-style: none;
`;

const ExtraFieldLabel = styled.label`
  margin: 0 10px 0 0;
  color: #e77817;
  text-align: left !important;
  width: auto !important;
  white-space: normal !important;
  display: block;
`;

const SignupNameContainer = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SignupName = styled.span`
  margin-right: 40px;
  flex: 1;
  white-space: ${({canWrap = true}) => canWrap ? 'normal' : 'nowrap'};
`;

const CancelIconContainer = styled.div`
  display: flex;
  align-items: center;
  @media print {
    display: none;
  }
  outline: 0;
  cursor: pointer;
`;

const CancelIcon = forwardRef((props, ref) => {
  return (
    <CancelIconContainer ref={ref}>
      <MdClose
        {...props}
      />
    </CancelIconContainer>
  );
});

const ExtraFieldValue = styled.div`
  @media print {
    color: #aaa;
  }
`;

const ExtraField = ({extraField: {name, value}}) => {
  return (
    <li>
      <ExtraFieldLabel>{name}</ExtraFieldLabel>
      <ExtraFieldValue>{value}</ExtraFieldValue>
    </li>
  );
};

const Signup = ({signup, onCancel}) => {
  const {name, mightBeMine, isExtra, isPending, extraFields = []} = signup;
  const {sheet: {isEditableByCurrentUser}} = useContext(SignupSheetContext);
  const canCancel = mightBeMine || isEditableByCurrentUser;
  const cancel = () => {
    onCancel(signup);
  }
  return (
    <SignupItem
      isExtra={isExtra}
      isPending={isPending}
    >
      <SignupNameContainer>
        <SignupName canWrap={name.length > 50}>{name}</SignupName>
        {canCancel &&
          <Tip content="Cancel this signup">
            <CancelIcon onClick={cancel} />
          </Tip>
        }
      </SignupNameContainer>
      {extraFields.length > 0 &&
        <ExtraFieldsList>
          {extraFields.map(extraField => <ExtraField
            key={extraField.id}
            extraField={extraField}
          />)}
        </ExtraFieldsList>
      }
    </SignupItem>
  );
};

export default Signup;
